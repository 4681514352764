export const CONTENTFUL_ENTRIES_ID: Record<string, string> = {
  EMPTY_HIGHLIGHT: 'highlight_empty',
  EMPTY_VIDEO: 'video_empty',
  EMPTY_RICH_ASSET: 'richAsset_empty',
  EMPTY_SIMPLE_CONTENT: 'simpleContent_empty'
};

export const CONSTANT_SEARCH_HIT_TYPE = {
  PRODUCT: 'product',
  SERVICE: 'service',
  CATEGORY: 'category'
};

export const ERROR_CODE: Record<string, string> = {
  PRODUCT_NOT_PUBLISHED_IN_COCKPIT: 'ERROR_PRODUCT_NOT_PUBLISHED_IN_COCKPIT'
};
export const RENDERING_SERVICE_URL = 'https://rendering.documents.cimpress.io:443/v1/exa/preview';
export const SCALETOFIT_SERVICE_URL = 'https://misc-rendering.documents.cimpress.io/scaletofit';

export const EXA_COLORS: Record<string, string | undefined> = {
  'exa pink': '#FF3D77', // available in contentful
  'exa blue': '#33D1FF', // available in contentful
  'exa yellow': '#FFB94B', // available in contentful
  'exa green': '#00C882', // available in contentful
  'exa orange': '#FF8241', // available in contentful
  'exa purple': '#8264E1', // available in contentful
  'exa pink 30': 'rgba(255, 61, 119, 0.3)', // available in contentful
  'exa blue 30': 'rgba(51, 209, 255, 0.3)', // available in contentful
  'exa yellow 30': 'rgba(255, 185, 75, 0.3)', // available in contentful
  'exa green 30': 'rgba(0, 200, 130, 0.3)', // available in contentful
  'exa orange 30': 'rgba(255, 130, 65, 0.3)', // available in contentful
  'exa purple 30': 'rgba(130, 100, 225, 0.3)', // available in contentful
  'exa pink 60': 'rgba(255, 61, 119, 0.6)', // available in contentful
  'exa blue 60': 'rgba(51, 209, 255, 0.6)', // available in contentful
  'exa yellow 60': 'rgba(255, 185, 75, 0.6)', // available in contentful
  'exa green 60': 'rgba(0, 200, 130, 0.6)', // available in contentful
  'exa orange 60': 'rgba(255, 130, 65, 0.6)', // available in contentful
  'exa purple 60': 'rgba(130, 100, 225, 0.6)', // available in contentful
  'exa light grey': '#F5F5F5', // available in contentful
  'exa light grey 200': '#ddd',
  'exa grey': '#D2D2D2', // available in contentful
  'exa grey 100': '#C2C2C2',
  'exa grey 300': '#989898',
  'exa grey 400': '#676767',
  'exa white': '#ffffff',
  'exa white 100': '#d8d8d8',
  'exa white 200': '#f8f8f8',
  'exa black': '#323232',
  'exa light yellow': '#fff9ec',
  'exa red': '#ff3d77',
  'exa light red': '#ffecf1',
  'exa error': '#D62020',
  'exa error 400': '#F00',
  'exa light error': '#FBE9E9',
  'exa white background': '#f1f1f1',
  'exa blue 700': '#337c99',
  'exa yellow 600': '#E5AE39',
  'exa yellow 400': '#fcc443',
  'exa yellow 300': '#fff8eb',
  'exa black 400': '#676767',
  'exa pink 300': '#fdecf0',
  'exalink purple': '#e8d1fc',

  // key from contentful Footer
  blue: '#33D1FF',
  yellow: '#FFC846',
  pink: '#FF3D77',
  white: '#ebebeb'
};

export const getColorFromCode = (colorCode: string | null | undefined, defaultColor: string): string => {
  if (colorCode) {
    const color = EXA_COLORS[colorCode];
    if (color) {
      return color;
    }
  }

  return defaultColor;
};
export const MOBILE_SIZE = 1024;
export const EXA_LANGS: Record<string, number> = {
  fr: 1,
  es: 2
};

export const FACETS = [
  'Brand_Name___LOV',
  'Size___LOV',
  'Color_Name___LOV',
  'DecorationTechnology___LOV',
  'Sleeves___LOV',
  'Fastener_Type___LOV',
  'Main_Compartment_Closure_Feature___LOV',
  'Shoulder_Straps___LOV'
];

export const MultiModelAttributeKey = 'Set___LOR';

export const USER_SEGMENT_KEYS: Record<string, string> = {
  RESELLER: '1',
  NON_RESELLER: '2',
  ANOME: '3',
  ES_RESELLER: '8',
  ES_NON_RESELLER: '9',
  ES_ANOME: '10'
};

export const USER_PROFILE_KEYS: Record<string, string> = {
  RFM_HIGH: '1',
  ACQUIRED: '2',
  RFM_LOW: '3',
  RFM_MEDIUM: '4',
  ACTIVATED: '5',
  RNB: '6',
  INACTIVE: '7',
  RESURECTED: '8',
  DORMANT: '9'
};

export const CONTENTBOX_PATCH = {
  PROMO: 'Promo',
  NEW: 'New',
  PRICEDOWN: 'PriceDown',
  OUTOFSTOCK: 'OutOfStock',
  BESTSELLER: 'BestSeller',
  FLASH: 'Flash',
  ECOFRIENDLY: 'EcoFriendly',
  MADEINFRANCE: 'MadeInFrance',
  MADEINSPAIN: 'MadeInSpain',
  NULL: 'Null' // used to prevent automatic Contentful locale fallback
};

export const SHOWONLYFOR = {
  NON_CONNECTED: 'Non-connected',
  NON_CONNECTED_OR_NON_BUYERS: 'Non-connected or non-buyers',
  ALL_CONNECTED: 'All connected',
  CONNECTED_RESELLERS: 'Connected resellers',
  CONNECTED_NON_RESELLERS: 'Connected non-resellers',
  ALL_RESELLERS: 'All resellers',
  ALL_NON_RESELLERS: 'All non-resellers',
  ALL_CUSTOMERS: 'All customers',
  ALL_REGISTERED_NON_BUYERS: 'All registered non-buyers',
  CUSTOMERS_RESELLERS: 'Customers resellers',
  CUSTOMERS_NON_RESELLERS: 'Customers non-resellers',
  REGISTERED_NON_BUYERS_RESELLERS: 'Registered non-buyers resellers',
  REGISTERED_NON_BUYERS_NON_RESELLERS: 'Registered non-buyers non-resellers'
};

export const SHOWONLYFORMULTIPLE = {
  NON_CONNECTED: 'Non-connected',
  NON_CONNECTED_OR_NON_BUYERS: 'Non-connected or non-buyers',
  ALL_CONNECTED: 'All connected',
  CONNECTED_RESELLERS: 'Connected resellers',
  CONNECTED_NON_RESELLERS: 'Connected non-resellers',
  ALL_CONNECTED_RESELLERS: 'All connected resellers', // Updated
  ALL_CONNECTED_NON_RESELLERS: 'All connected non-resellers', // Updated
  ALL_CONNECTED_CUSTOMERS: 'All connected customers', // Updated
  ALL_CONNECTED_NON_BUYERS: 'All connected non-buyers', // Updated
  CONNECTED_CUSTOMERS_RESELLERS: 'Connected customers resellers', // Updated
  CONNECTED_CUSTOMERS_NON_RESELLERS: 'Connected customers non-resellers', // Updated
  CONNECTED_NON_BUYERS_RESELLERS: 'Connected non-buyers resellers', // Updated
  CONNECTED_NON_BUYERS_NON_RESELLERS: 'Connected non-buyers non-resellers' // Updated
};

// Alternatives to 'Size___LOV' for the size attribute.
export const SIZE_ATTRIBUTE_KEYS = ['Outerwear_Set___LOV'];
export const SITE_KEY_RECAPTCHA = '6LcOvm8aAAAAAG2TFZGtmFuPA_SzNg3ZsCEr7pYy';

export const MAX_MULTI_MODEL = 20;
export const EXAPRODUCTTYPE_LEGACY = 'LEGACY';
export const EXAPRODUCTTYPE = 'EXAPRODUCTTYPE';
export const EXAPRODUCTTYPE_CGF = 'CGF';
export const EXAPRODUCTTYPE_MCPPRICE = 'MCPPRICE';
export const EXAPRODUCTTYPE_MCP = 'MCP';

export const EXAPRODUCTTYPE_WITH_QUOTES = [EXAPRODUCTTYPE_LEGACY, EXAPRODUCTTYPE_CGF, EXAPRODUCTTYPE_MCPPRICE];

export const PRODUCT_ATTRIBUTE_PATCH = {
  ECOLOGICAL: 'Ecological',
  PRICEDOWN: 'PriceDown',
  OUTOFSTOCK: 'OutOfStock',
  BESTSELLER: 'BestSeller',
  PREMIUM: 'Premium',
  RECOMMEND: 'Recommend',
  MADEIN: 'MadeIn',
  NEW: 'New',
  STANDARD: 'Standard',
  ECONOMIC: 'Economic'
};

/** By convention, attributes that are specific to a multimodels have their name ends with " M[x]", ex : "Hauteur M1",
 with "x" the  model number it is related to.
**/
export const DEFAULT_MAX_MULTI_MODEL_COUNT = 7;
export const MULTIMODEL_NAME_REGEXP = /\sM([0-9]{1,})$/;
export const MODEL_COUNT_ATTRIBUTE_NAME = 'Nombre de modèles';
export const MODEL_COUNT_ADESA_ATTRIBUTE_NAME = 'Multimodeles';
export const ATTRIBUTE_NAME_QDA_SETS = 'Sets';
export const ATTRIBUTE_KEY_QDA_SETS = 'Sets___LOR';
export const ATTRIBUTE_NAME_QDA_NUMBER_OF_ROLLS = 'Number of Rolls';

export const MODEL_COUNT_ATTRIBUTE_NAMES = [MODEL_COUNT_ATTRIBUTE_NAME, MODEL_COUNT_ADESA_ATTRIBUTE_NAME];
export const QDA_MODEL_COUNT_ATTRIBUTE_NAMES = [ATTRIBUTE_NAME_QDA_SETS];

export const MODEL_QUANTITY_ATTRIBUTE_NAME = 'Quantité totale';
export const MODEL_SURFACE_ATTRIBUTE_NAME = 'Surface totale';
export const CERTIFICATE_ATTRIBUTE_NAME = 'Certificate';

export const PRECISE_STICKERS_PER_ROLL_ATTRIBUTE_NAME = 'PreciseStickersPerRoll';
export const QUANTITY_BY_ROLL_ATTRIBUTE_NAME = 'Label Quantity Per Roll';
export const QUANTITY_BY_ROLL_ATTRIBUTE_KEY = 'Label_Quantity_Per_Roll___LOR';
export const MERCHANT_QUANTITY_BY_ROLL_ATTRIBUTE_NAME = 'Merchant Label Quantity Per Roll';
export const QDA_ROLL_DIAMETER_ATTRIBUTE_NAME = 'Roll Outside Diameter';
export const ROLL_NUMBER_ATTRIBUTE_NAME = 'Number of Rolls';
export const ADESA_ROLL_DIAMETER_ATTRIBUTE_KEY = 'Adesa_Roll_Diameter';
export const ADESA_ROLL_DIAMETER_ATTRIBUTE_NAME = 'Adesa Roll Diameter';
export const ROLL_NUMBER_ATTRIBUTE_KEY = 'Number_of_Rolls___LOR';
export const STICKER_APPLICATION_TYPE_ATTRIBUTE_NAME = 'automated_fix';
export const STICKER_AUTOMATIC_APPLICATION_VALUE_NAME = '1';
export const STICKER_MANUAL_APPLICATION_VALUE_NAME = '0';
export const ATTRIBUTE_KEY_GRAPHIC_CREATION = 'Graphic_creation___LOV';

export const ATTRIBUTE_NAME_IS_STICKERS_API = 'isAdesa';
export const STICKER_ROLL_DIAMETER_EXTERNAL_DATA = 'rollDiameter';
export const SHIPPABLE_PACK_WEIGHT = 'Shippable Pack Weight';
export const MAX_GENERATED_OPTIONS = 200;
export const AUTOFILL = 'autofill';
export const AUTOFILL_COUNTRY = 'autofill_country';
export const HEIGHT_ATTRIBUTE_KEY = 'Hauteur';

export const InknpaperPageType = 'InknpaperPage';
export const HomePageType = 'home';
export const DesignerPageType = 'DesignerPage';

// local storage key
export const LOCAL_STORAGE_SORTING_KEY = 'menu_sorting_type';
export const LOCAL_STORAGE_KEY_PROMO_BAR_DISPLAY_CACHE = 'PROMO_BAR_DISPLAY_CACHE';
export const LOCAL_STORAGE_KEY_POP_IN_REGISTRATION = 'POP_IN_REGISTRATION';
export const LOCAL_STORAGE_KEY_USER_GUIDING_RANDOM_ID = 'USER_GUIDING_RANDOM_ID';
export const LOCAL_STORAGE_KEY_PRODUCT_PAGE_GUIDED_CONFIGURATION = 'PRODUCT_PAGE_GUIDED_CONFIGURATION';

export const DUMMY_ID_BLOG_POST = 'id_dummy_blog_post';

export const UNIVERSE_SECTION_TYPES = {
  PRODUCTS_OR_CATEGORYPAGE: 'products or category pages list',
  HIGHLIGHT_NO_IMAGE: 'highlight without image',
  HIGHLIGHT_IMAGE: 'highlight with image',
  HIGHLIGHT_CONTENTBOXES: 'highlight with contentBox',
  FAQ: 'FAQ'
};

//default scenes proportions for cockpit images (CTL-2105)
export const DEFAULT_COCKPIT_IMAGES_DIMENSIONS = {
  WIDTH: 600,
  HEIGHT: 600
};

export const DEFAULT_COCKPIT_IMAGES_BGCOLOR = null;

/*Exalink offer are only FR, only this two product can't have Exalink offer */
export const PRODUCT_LIST_WITHOUT_EXALINK_OFFER = ['Carte de visite papier classique', 'Carte de visite haut de gamme'];

export const PACKAGING_SLUG_FR = 'le-packaging';
export const PACKAGING_SLUG_ES = 'packagings';
export const GOODIES_SLUG_FR = 'goodies';
export const GOODIES_SLUG_ES = 'merchandising';

export const getIsPackaging = (universePageSlug: string, locale: string) => {
  const packagingSlug = locale === 'es' ? PACKAGING_SLUG_ES : PACKAGING_SLUG_FR;
  return universePageSlug === packagingSlug;
};

export const getIsGoodies = (universePageSlug: string, locale: string) => {
  const goodiesSlug = locale === 'es' ? GOODIES_SLUG_ES : GOODIES_SLUG_FR;
  return universePageSlug === goodiesSlug;
};
